import * as React from "react"

const About = () => {
    return (
        <div className="wrapper">
            <div className="section-title">
                <h2 className="section-title__primary">
                    Need urgent help with a term paper?
                </h2>
                <div className="section-title__secondary">
                    We write 24/7! No plagiarism. Any difficulty, any specs, any deadline! Confidentiality guaranteed!
                </div>
            </div>

            <section className="statistics-section">
                <div className="statistics-section__accent">
                    <div className="statistics-number statistics-number--left-side">
                        <div className="statistics-number__number-info statistics-number__number-info--dot-decor">
                            18
                        </div>
                        <div className="statistics-number__text-info statistics-year-icon">years</div>
                    </div>
                </div>

                <div className="statistics-section__text">
                    <h2 className="statistics-section__text-title">Genuine History</h2>
                    <p className="p-origin">
                        Genuinewriting.com is a term paper service with <b>18 years of history</b>, founded in 2003. We were the pioneers of the term paper writing industry. Today, we are a solid writing organization with vast experience which is enough to provide each client with genuine writing help.
                    </p>
                    <p className="p-origin">
                        From our early days, we have been devoted to providing an unsurpassed level of service to anyone struggling with writing assignments such as essays, term papers, research papers, book reports, theses, dissertations, business plans, grant proposals, etc.
                    </p>
                </div>
            </section>

            <section className="statistics-section">
                <div className="statistics-section__text statistics-section__text--right">
                    <h3 className="statistics-section__text-title statistics-section__text-title--right">A Genuine Service</h3>
                    <div className="p-origin">
                        If you have a coursework assignment that you need help with, you've come to the right place. Whatever the subject, we have writers of the highest caliber to assist you 24/7. Over 90% of our clients come to place their second order.
                    </div>
                    <p className="p-origin">
                        That is only to testify that our genuine service is always as advertised: to every client, we guarantee 100% genuine non-plagiarized writing done from scratch by an experienced American writer, meeting all instructions and delivered on time.
                    </p>
                </div>

                <div className="statistics-section__accent">
                    <div className="statistics-number statistics-number--right-side">
                        <div className="statistics-number__number-info">24/7</div>
                        <div className="statistics-number__text-info statistics-support-icon">support</div>
                    </div>
                </div>
            </section>

            <section className="statistics-section">
                <div className="statistics-section__accent">
                    <div className="statistics-number statistics-number--left-side">
                        <div className="statistics-number__number-info statistics-number__number-info--dot-decor">5</div>
                        <div className="statistics-number__text-info statistics-time-icon">min</div>
                    </div>
                </div>

                <div className="statistics-section__text">
                    <h3 className="statistics-section__text-title">A Genuine Website</h3>
                    <p className="p-origin">
                        Our website allows for fast, safe (256-SSL Secured), and confidential ordering. It takes less than 5 minutes to get your project underway. You place an order with an in-depth description of your assignment and we assign the project to a professional essay writer who has the qualifications to complete the job. All essay writers in our company are certified, have proven themselves as professionals and have years of experience under their belt. Every project is researched from scratch and hand-typed, guaranteed!
                    </p>
                </div>
            </section>
        </div>
    )
}

export default About