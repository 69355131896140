import React from "react"
import Layout from "@components/layout"
import Seo from "@components/Seo.js"
import Intro from "@components/mainpage/Intro"
import About from "@components/mainpage/About"
import Services from "@components/mainpage/Services"
import WhyUs from "@components/mainpage/WhyUs"
import Calculator from "@components/Calculator"
import { Helmet } from "react-helmet"
import TheOurServices from "@components/mainpage/TheOurServices"
import TheHowWeWork from "@components/mainpage/TheHowWeWork"

const IndexPage = () => {

    const pageData = {
        title: `Best Essay Writers Online`,
        description: `⭐⭐⭐⭐⭐ Looking for a good writer for your assignment? Our platform can deliver! ⏰ 24/7 Support, 🔓 Full Confidentiality, ✔️ 100% Plagiarism-free!`,
        keywords: `write my paper, write my essay, do my assignment, essay, research paper, do my term paper, dissertation, thesis, book report`,
        micromarking: `
            {
                "@context": "https://schema.org",
                "@type": "Organization",
                "url": "https://www.genuinewriting.com",
                "name": "GenuineWriting.com",
                "images":"https://www.genuinewriting.com/img/genuinewriting.png",
                "description":"Looking for a professional writer for your essay, term paper, book report or dissertation?✍️ Our platform is home to hundreds of experts. ⏰ 24/7 Support, 🔓 Full Confidentiality, ✔️ 100% plagiarism-free!"
            }
        `
    }

    return (
    <>  
        <Seo {...pageData} />
        <Layout>
            <Intro />
            <About />
            <Services />
            {/* <Calculator /> */}
            {/* <TheOurServices /> */}
            {/* <WhyUs/> */}
            {/* <TheHowWeWork /> */}
            {/* <Helmet>
                <script type="application/ld+json">
                    {`
                        {
                            "@context": "https://schema.org",
                            "@type": "Organization",
                            "url": "https://www.genuinewriting.com",
                            "name": "GenuineWriting",
                            "images":"https://www.genuinewriting.com/img/genuinewriting.png",
                            "description":"GenuineWriting.com is a competent online writing platform with over 250 writers on the team. Any topic, any deadline, any project can be handled."
                        }
                    `}
                </script>
            </Helmet> */}
        </Layout>
    </>
    )
}

export default IndexPage