import * as React from "react"

const WhyUs = () => {
    return (
        <section id="hww-anchor" className="choose-section">
            <div className="section-title">
                <h2 className="section-title__primary">
                    WHY YOU SHOULD CHOOSE US OVER THE REST
                </h2>
            </div>
            <div className="choose-section__list wrapper">
                <div className="choose-section__item">
                    <div className="choose-section__icon">
                        <span
                            className="choose-section__img choose-section__img--contract"
                        />
                    </div>
                    <div className="choose-section__info">
                        <div className="choose-section__title">
                            Better Writers
                        </div>
                        <p className="choose-section__text">
                            We pay very high rates to our writers, hence we can employ the
                            best. Other essay writing companies pay freelance writers as little as $2 US dollars a page
                            and, as a result, deliver plagi
                            arized content with few words changed here and there.e
                        </p>
                    </div>
                </div>
                <div className="choose-section__item">
                    <div className="choose-section__icon">
                        <span
                            className="choose-section__img choose-section__img--monitor"
                        />
                    </div>
                    <div className="choose-section__info">
                        <div className="choose-section__title">
                            Better Website
                        </div>
                        <p className="choose-section__text">
                            We have a high-tech modern website with great and efficient design and fantastic features. Our
                            checkout process is quick and beautiful. Most other essay writing websites are old, built on
                            outdated technology with terrible checkout flows.
                        </p>
                    </div>
                </div>
                <div className="choose-section__item">
                    <div className="choose-section__icon">
                        <span
                            className="choose-section__img choose-section__img--support"
                        />
                    </div>
                    <div className="choose-section__info">
                        <div className="choose-section__title">
                            Better Support
                        </div>
                        <p className="choose-section__text">
                            Most other essay writing services only match their buyers with random freelance writers. They
                            all have on-line chat, but for sales inquiries only! Hence, the buyers are on their own! We, on
                            the other hand, have a 24/7 dedicated support team that actually works with the writers and
                            resolves problems when they arise.
                        </p>
                    </div>
                </div>
                <div className="choose-section__item">
                    <div className="choose-section__icon">
                        <span
                            className="choose-section__img choose-section__img--currency"
                        />
                    </div>
                    <div className="choose-section__info">
                        <div className="choose-section__title">
                            No Hidden Costs
                        </div>
                        <p className="choose-section__text">
                            It might be shocking, but many other sites charge their clients extra money for features, as
                            simple as e-mail notifications. They have lots of hidden costs! With our site, you get
                            everything out of the box, for free, all included in the advertised price!
                        </p>
                    </div>
                </div>
                <div className="choose-section__item">
                    <div className="choose-section__icon">
                        <span
                            className="choose-section__img choose-section__img--network"
                        />
                    </div>
                    <div className="choose-section__info">
                        <div className="choose-section__title">
                            Better Team
                        </div>
                        <p className="choose-section__text">
                            We have a great experienced team behind our product. Our writers and managers have over 10 years
                            of experience in the field. 90% of the sites you find on Google have only search engine
                            marketing teams who are very good at promoting sites. Sadly, that’s where it ends for the buyer.
                        </p>
                    </div>
                </div>
                <div className="choose-section__item">
                    <div className="choose-section__icon">
                        <span
                            className="choose-section__img choose-section__img--money"
                        />
                    </div>
                    <div className="choose-section__info">
                        <div className="choose-section__title">
                            Clear Refund Policy
                        </div>
                        <p className="choose-section__text">
                            We have a clear and simple refund policy that just works. If we don't deliver or under-deliver,
                            you just get your money back. No questions asked!
                        </p>
                    </div>
                </div>
            </div>
        </section>  
    )
}

export default WhyUs
