import * as React from "react"

const Intro = () => {
    return (
        <div className="first-screen">
            <div className="wrapper">
                <h1 className="first-screen__title">
                    A Bespoke Writing Service!<br/> As Genuine As It Gets
                </h1>
                <p className="first-screen__descr">
                    Do you need a ghostwriter for your academic written assignment? Do you need a business copywriter for your marketing copy? We handle complex assignments on a very short notice with proven results!
                </p>
                <a href={process.env.SHOP_API + "/order/new"} className="btn-base button-primary btn-base_large btn-base_double first-screen__btn">Order Your Essay Here</a>
            </div>
        </div>
    )
}

export default Intro
