import * as React from "react"

const Services = () => {
    return (
        <div className="wrapper">
            <div className="section-title">
                <h2 className="section-title__primary">A Genuine Package</h2>
            </div>

            <section className="statistics-section__services">
                <ul className="service-list">
                    <li className="percent-icon">
                        You will receive a 100% genuine (all projects are done to order and produced entirely from scratch) academic work delivered on time, fully meeting the requirements of the order and featuring current research, proper grammar/wording and correctly formatted bibliographic citations (MLA, APA, Chicago, Harvard, AMA, etc.).
                    </li>

                    <li className="writing-icon">
                        As a customer of Genuine Writing, you are entitled to free unlimited revisions and rewrites. If you believe there are ways to improve our work, all you have to do is submit a revision request and your personal staff writer will add the final touches to the paper in no time. Unlike other companies, we do not charge for additional items such as abstract, outline, bibliography, footnotes, endnotes and provide those upon request absolutely free of charge.
                    </li>

                    <li className="result-icon">
                        We are offering a sophisticated on-line account facility where you can manage all your projects, exchange files and messages with our writers and managers, download project files upon completion of your order, request rewrites and more. You will truly enjoy using your personal account.
                    </li>
                </ul>
            </section>
        </div>
    )
}

export default Services